import {ref} from 'vue';
import { useToast } from 'vue-toastification';

const toast = useToast();
const name = ref('');
const personality = ref('');
const deviceId = ref(window.deviceId || null);

const getLocation = async () => {
  deviceId.value = window.deviceId;
};

window.locationSetCallback = async () => {
  console.log('funcs locationSetCallback')
  await fetchSettings();
};

const fetchSettings = async () => {
  try {
    console.log('funcs fetchSettings')
    await getLocation();
    const response = await fetch('https://pro.mel0ng.kr/api.php', {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify({
        apiName: 'getCertificate',
        deviceId: window.deviceId,
      }),
    });

    if (response) {
      const { user_name, user_prompt } = await response.json();
      if (user_name) name.value = user_name;
      if (user_prompt) personality.value = user_prompt;
    } else {
      console.error('Error fetching settings');
    }
  } catch (error) {
    console.error('Error fetching settings:', error);
  }
};
const saveSettings = async () => {
  if (name.value.length < 2) {
    alert('이름은 최소 2글자 이상이어야 합니다.');
    return;
  }

  const data = {
    user_name: name.value,
    user_prompt: personality.value,
  };

  try {
    const response = await fetch('https://pro.mel0ng.kr/api.php', {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify({
        apiName: 'setCertificate',
        data: data,
        deviceId: window.deviceId,
      }),
    });

    if (response) {
      toast.success('설정이 성공적으로 저장되었습니다.');
    } else {
      toast.error('설정 저장 중 오류가 발생했습니다.');
    }


  } catch (error) {
    console.error('Error saving settings:', error);
    toast.error('설정 저장 중 오류가 발생했습니다.');
  }
};

export { name, personality, saveSettings };