<script setup>
import {ref} from "vue";
const lat = ref(window.windowLat || null);
const lng = ref(window.windowLng || null);

function getLocation(){
  lat.value = window.windowLat;
  lng.value = window.windowLng;
}
setInterval(getLocation, 100);
</script>

<template>
  <div>
    <p>lat : {{lat}}</p>
    <p>lng : {{lng}}</p>
  </div>

</template>