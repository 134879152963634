import { createRouter, createWebHistory } from 'vue-router';
import Faq from '../views/FaqPage.vue';
// import Home from '../views/HomePage.vue';
import Chat from '../views/ChatPage.vue';
import Tplink from '../views/TplinkPage.vue';
import Map from '../views/MapPage.vue';
import Cert from '../views/CertPage.vue';


import CameraComponent from '../components/CameraComponent.vue';

const routes = [
  {
    path: '/',
    name: 'Home',
    component: Chat
  },
  {
    path: '/cert',
    name: 'CertComponent',
    component: Cert
  },
  {
    path: '/faq',
    name: 'FaqComponent',
    component: Faq
  },
  {
    path: '/chat',
    name: 'ChatComponent',
    component: Chat
  },
  {
    path: '/tplink',
    name: 'TplinkComponent',
    component: Tplink
  },
  {
    path: '/camera',
    name: 'CameraComponent',
    component: CameraComponent
  },
  {
    path: '/map',
    name: 'MapComponent',
    component: Map
  },
  // 다른 경로도 추가할 수 있습니다.
];

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes,
  linkActiveClass: "active",
  linkExactActiveClass: "active"
});

export default router;